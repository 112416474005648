.logo {
    height: 1.1em;
    margin: 0 0 3px 5px!important;
}

:root {
    /* sizes */
    --ticker-tape-height: 55px;
    --navbar-height: 70px;
    --footer-height: 128px;
    --sidebars-margin: 15px;

    /* colors */
    --numeric-green: rgb(15, 152, 129);
    --numeric-red: rgb(241, 67, 75);
    --numeric-blue: #2866F8;
    --numeric-dark-blue: #082535;
    --numeric-text: #5B6E86;
    --numeric-bg: #F6F7FF;

    /* containers */
    --numeric-border: #E5E7EB;
    --numeric-container-bg: #FCFCFC;

    /* dark mode */
    --darkreader-bg--numeric-dark-blue: #6A5ACD ;
    --darkreader-border--numeric-dark-blue: #6A5ACD;
    --darkreader-bg--numeric-blue: #6A5ACD ;
    --darkreader-border--numeric-blue: #6A5ACD;
    --darkreader-text--numeric-text: #A5A5A5;
    --darkreader-border--numeric-green: rgb(15, 152, 129);
    --darkreader-text--numeric-green: rgb(15, 152, 129);
    --darkreader-border--numeric-red: rgb(241, 67, 75);
    --darkreader-text--numeric-red: rgb(241, 67, 75);

    /* footer */
    --numeric--footer-bg: #1F1F1F;
    --numeric-footer-link: #BABFC4;
    --numeric-footer-text: #9199A1;
}

.ticker-tape-link {
    color: var(--numeric-text)!important;
}

iframe {
    display: none;
}

.menu-item-border {
    border-width: 3px !important;
    border-right: 3px solid var(--numeric-dark-blue)!important;
}

/*.even-row .MuiDataGrid-cell {
    background-color: var(--numeric-container-bg)!important;
}*/

.home-button {
    background: var(--numeric-dark-blue)!important;
    border: 0!important;
}

.log-in-button {
    background: black!important;
    border: var(--numeric-border)!important;
}

.sign-up-button {
    background-color: var(--numeric-blue)!important;
    border: 0!important;
}

body {
    font-family: 'Uber Move Text', sans-serif !important;
    margin-bottom: var(--footer-height) !important;
    background-color: white!important;
    min-height: calc(100dvh - var(--footer-height))!important;
}

.content {
    padding-top: var(--navbar-height);
}

html {
    position: relative;
    min-height: 100%;
}

h4 > a {
    margin-right: 1rem;
}

#home-textarea {
    height: 100px;
}

.brand {
    font-weight: 500;
}

.footer {
    background-color: var(--numeric--footer-bg) !important;
    padding: 20px;
    font-size: 12px;
    color: var(--numeric-footer-text);
    height: var(--footer-height) !important;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.navbar-container {
    border-top: 3px solid black;
    height: var(--navbar-height) !important;
    background-color: white!important;
}

.footer ul {
    margin: auto !important;
    max-width: 350px !important;
    margin-bottom: 5px !important;
}

.footer a {
    color: var(--numeric-footer-link);
    font-size: 12px;
    font-weight: bold;
}

.header-search {
    width: 100% !important;
}

.navbar-container, .navbar-container input, .navbar-container button {
    font-size: 14px;
}

.d-contents {
    display: contents !important;
}

.navbar-toggler, .navbar-toggler:focus, .navbar-toggler:active {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.container {
    max-width: 1366px !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

.ps-menu-root {
    padding-right: var(--sidebars-margin)!important;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .only-mobile {
        display: none !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .ps-menu-root {
        padding-right: 0!important;
    }

    .logo {
        height: 1.2em;
        margin: 0 0 4px 3px!important;
    }

    .sidebar {
        position: relative !important;
        background-color: transparent!important;
        top: 0 !important;
        margin-top: 10px!important;
        border-right: 0!important;
    }

    .sidebar a {
        margin-top: 0!important;
    }

    .header {
        position: relative !important;
        width: auto !important;
    }

    .content {
        padding-top: 0 !important;
    }

    .content-page {
        text-align: justify !important;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

}

.header {
    position: fixed;
    width: 100%;
    z-index: 11;
}

.navbar-toggler-icon {
    background-image: none !important;
    margin-top: 7px;
}

.navbar-toggler-icon::before {
    font-family: FontAwesome;
    content: "\f0c9"; /* fa-bars, fa-navicon */
}

.dropdown-item {
    font-size: 12px;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: calc(100dvh - var(--navbar-height) - var(--footer-height));
}

.box-container {
    width: 300px;
    max-width: 100vw;
}

.box input {
    font-size: 15px;
}

.box label {
    font-weight: 500 !important;
    margin-bottom: 3px;
}

.box button {
    font-size: 15px;
}

.box-up-button {
    font-size: 14px !important;
    background-color: white !important;
    color: rgb(59, 64, 69) !important;
    border: none!important;
}

h1 {
    font-weight: 700 !important;
    font-size: 1.75rem !important;
    margin-bottom: 1.5rem !important;
}

h2 {
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    margin-bottom: 0.75rem !important;
}

.list-group-item {
    border: 0 !important;
}

.list-group {
    border-radius: 0 !important;
}

ul.no-bullet {
    list-style-type: none;
}

.selected {
    border-right: 3px solid black !important;
}

.content-page {
    max-width: 800px !important;
    border-radius: 5px;
    margin: 10px 12px 10px 12px!important;
    background-color: var(--numeric-container-bg)!important;
    border: 1px solid #E5E7EB!important;
}

.numeric-link {
    color: var(--numeric-blue)!important;
    font-weight: 700 !important;
    text-decoration: none;
}

.numeric-link-secondary {
    color: var(--numeric-text)!important;
    font-weight: 700 !important;
    text-decoration: none;
}

.post-date {
    font-size: 11px;
    color: var(--numeric-text) !important;
}

.post-source {
    font-size: 11px;
    color: var(--numeric-green);
}

.post-description {
    font-size: 0.75rem;
}

.paginate {
    gap: 5px;
    list-style-type: none;
    margin-left: -1px;
}

.paginate .page-number {
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--numeric-text);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1px;
}

.paginate .page-number-dots {
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--numeric-text);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 1px;
}

.paginate .page-number:hover {
    color: white;
    background: var(--numeric-dark-blue);
}

.paginate .active {
    color: white;
    background: var(--numeric-dark-blue);
}

.paginate-container {
    margin: 20px 0 10px 0;
}

.MuiDataGrid-cell {
    color: var(--numeric-text)!important;
    font-weight: bold;
    background-color: white!important;
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeader, .MuiDataGrid-root, .MuiDataGrid-cell a {
    border-top: 0!important;
    border-right: 0!important;
    border-left: 0!important;
    font-size: 13px !important;
    font-family: 'Uber Move Text', sans-serif !important;
}

.MuiDataGrid-cell {
    border-bottom: 1px solid #E5E7EB!important;
}


.MuiDataGrid-withBorderColor {
    border-color: #E5E7EB!important;
}

.MuiTablePagination-root p {
    margin: 0 !important;
}

.Mui-hovered {
    background-color: white!important;
}

.MuiDataGrid-footerContainer, .MuiDataGrid-root {
    border: 0!important;
}

.MuiDataGrid-columnHeaderTitle {
    font-size: .6rem !important;
    text-transform: uppercase;
    font-weight: 500;
}

.MuiDataGrid-sortIcon {
    display: none !important;
}

.ticker-icon {
    border-radius: 10px;
    width: 20px;
}

.MuiDataGrid-iconButtonContainer {
    display: none !important;
}

.MuiDataGrid-columnHeader {
    outline: 0 !important;
}

.MuiTablePagination-root div, .MuiTablePagination-root p {
    font-size: 0.75rem !important;
    font-family: 'Uber Move Text', sans-serif !important;
    color: var(--numeric-text);
}

.custom-col-4-5 {
    flex: 0 0 36.5%;
    max-width: 36.5%;
}

.ticker-price {
    font-size: 1rem;
    margin-left: 10px;
}

.MuiChartsLegend-root tspan {
    font-family: 'Uber Move Text', sans-serif !important;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: bold;
}

.MuiChartsAxis-root tspan {
    font-family: 'Uber Move Text', sans-serif !important;
    font-size: .7rem;
}

.MuiChartsTooltip-root p {
    font-family: 'Uber Move Text', sans-serif !important;
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: bold;
}

.ticker-info-icon {
    border-radius: 10px;
    width: 15px;
    margin: 0 5px 0 0;
}

.ticker-tags {
    list-style-type: none;
    margin: 5px 0 15px 0!important;
    padding: 0 0 15px 0!important;
}

.ticker-tags li {
    font-size: 0.7rem;
    font-weight: 600;
    background: white;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 5px 0 0;
    float: left;
}

.ticker-info {
    border: 1px solid #E5E7EB!important;
    border-radius: 5px;
    padding: 10px;
}

.post-container {
    margin-bottom: 15px!important;
    color: var(--numeric-text)!important;
    font-weight: bold!important;
    border: 1px solid #E5E7EB!important;
    background-color: var(--numeric-container-bg)!important;
}

.auth-form {
    background-color: var(--numeric-container-bg);
    border: 1px solid #E5E7EB!important;
}

.recaptcha-logo {
    height: 100px;
}

.form-control {
    font-size: 14px!important;
}

.MuiTablePagination-actions button {
    box-shadow: none!important;
}

button {
    font-size: 14px!important;
    font-weight: bold!important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px!important;
}

.sidebar {
    font-size: 13px !important;
    position: fixed;
    top: var(--navbar-height);
    height: 100% !important;
    z-index: 10;
    background-color: var(--numeric-container-bg);
    border-right: 3px solid var(--numeric-dark-blue);
}

.ps-sidebar-container {
    margin-top: -10px!important;
    background-color: white!important;
}

.ps-menu-icon {
    margin: 0 !important;
}

.post-image {
    max-height: 100px!important;
}

.sidebar a {
    color: black!important;
    border-radius: 3px!important;
    height: 56px!important;
    background: white!important;
    margin-top: 5px;
}

.sidebar li {
    border-color: var(--numeric-dark-blue)!important;
}

.ps-menu-button {
    padding-right: var(--sidebars-margin)!important;
    font-weight: normal!important;
    background: white!important;
}

.ps-menu-button:hover {
    background: white!important;
}

.ps-open > .ps-menu-button {
    padding-left: var(--sidebars-margin)!important;
}

.submenu-item {
    font-size: 0.75rem !important;
}

.ps-sidebar-root {
    border: 0!important;
}

.numeric-dark-blue {
    background-color: var(--numeric-dark-blue)!important;
    border-color: var(--numeric-dark-blue)!important;
}

.numeric-green-text {
    color: var(--numeric-green)!important;
}

.numeric-red-text {
    color: var(--numeric-red)!important;
}

.numeric-green-border {
    border: 1px solid var(--numeric-green)!important;
}

.numeric-red-border {
    border: 1px solid var(--numeric-red)!important;
}

.pricer-up {
    color: var(--numeric-green)!important;
}

.pricer-down {
    color: var(--numeric-red)!important;
}

.scrollable-row {
    overflow-x: auto;
    white-space: nowrap;
}

.scrollable-row .row {
    flex-wrap: nowrap;
}

.loader {
    padding: 20px!important;
    text-align: center!important;
    font-size: 13px!important;
    font-weight: bold!important;
    border-radius: 3px!important;
    border: 1px dashed var(--numeric-border);
    margin-top: 15px!important;
}

.MuiDataGrid-iconSeparator{
    display: none!important;
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden!important;
}

.nav-link {
    font-weight: bold!important;
    font-size: 12px!important;
}

.MuiDataGrid-columnHeader {
   color: black!important;
}

.tradingview-widget-container {
    margin-bottom: 8px!important;
}

.react-tooltip {
    max-width: 250px!important;
}

.ticker-tape {
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
    height: var(--ticker-tape-height);
}

.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within {
    outline: 0!important;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
    overflow: visible!important;
}

.mini-container {
    border: 1px solid #E5E7EB!important;
    border-radius: 3px!important;
    padding: 10px 5px 5px 5px!important;
    color: var(--numeric-dark-blue)!important;
    font-size: 12px!important;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold!important;
}

.chart-container {
    border: 1px solid #E5E7EB!important;
    border-radius: 3px!important;
    margin-bottom: 15px!important;
    padding: 10px 5px 5px 5px!important;
    color: var(--numeric-dark-blue)!important;
    font-size: 13px!important;
    text-transform: uppercase;
    text-align: center;
}

.gauge-container {
    padding: 5px!important;
    border: 1px solid #E5E7EB!important;
    border-radius: 3px!important;
    margin-top: 17px!important;
}

.ag-charts-wrapper {
    outline-width: 0!important;
    border-width: 0!important;
}

.numeric-text-color {
    color: var(--numeric-text)!important;
}

.react-toggle-track {
    background-color: #A6AEBF!important;
    height: 20px!important;
    width: 40px!important;
}

.react-toggle-thumb {
    height: 18px!important;
    width: 18px!important;
}

.react-toggle--checked .react-toggle-thumb {
    left: 21px!important;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: var(--numeric-blue)!important;
}

.custom-toggle.react-toggle .react-toggle-thumb {
    border: 0!important;
    box-shadow: none!important;
}

.filters-toggle.react-toggle .react-toggle-track {
    height: 20px!important;
}

.react-toggle-thumb {
    border-color: white!important;
}